//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModel: () => import("@/components/XModal"),
    mb: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    operationName: {
      type: String | Number,
      default: 1,
    },
    activeItem: {
      type: String,
      default: "部门",
    },
    id: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      loadForm: {
        Words: [undefined, undefined, undefined, undefined, undefined],
        ProgressGroupIds: [],
        Behavior: null,
        BehaviorInCheck: null,
      },
      processOptions: [],
      user: [],
      copyForm: "",
      subLoading: false,
      loading: false,
    };
  },
  methods: {
    GetProgressGroup() {
      this.$http
        .post(
          "/Management/ProgressManagement/GetSystemProgressGroupSelected.ashx",
          { configId: this.selRow.Id }
        )
        .then((resp) => {
          if (resp.res == 0) {
            this.processOptions = resp.data;
          }
        });
    },
    beforeOpen() {
      this.loading = false;
      this.$nextTick(() => {
        this.GetProgressGroup();
        if (this.operationName == 2) {
          this.getDetail();
        }
      });
    },
    getDetail() {
      this.loading = true;
      this.$http
        .get(
          "/Management/SystemBehaviorAnalyse/GetSystemBehaviorAnalyseDetail.ashx",
          {
            params: { Id: this.id },
          }
        )
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.loadForm.ProgressGroupIds = res.data.PreocessGroup.map(
              (m) => m.ID
            );
            this.loadForm.Behavior = res.data.Behavior;
            this.loadForm.BehaviorInCheck = res.data.BehaviorInCheck;
            this.loadForm.Words = res.data.Words;
          }
        });
    },
    init() {
      Object.assign(this.$data, this.$options.data());
    },
    addMore() {
      let arr = this.loadForm.Words.filter((item) => {
        return item == undefined || "";
      });
      if (!arr.length) {
        this.loadForm.Words.unshift(undefined);
      }
    },
    filterParams(params) {
      let _params = { ...params };

      for (let key in _params) {
        if (_params[key] instanceof Array) {
          _params[key] = JSON.stringify(_params[key]);
        }
      }
      return _params;
    },
    check() {},
    //提交
    submit() {
      if (this.subLoading) {
        return;
      }
      let bool = this.copyForm == JSON.stringify(this.loadForm);
      if (bool && this.operationName == 2) {
        this.$modal.hide("actionRuleXmodal");
        return;
      } else {
        let word = [];
        this.loadForm.Words.forEach((item) => {
          //关键词
          if (item) {
            word.push(item);
          }
        });

        let flag = false;
        let str = "";
        if (this.loadForm.ProgressGroupIds.length == 0) {
          flag = true;
          str = "请选择作用部门";
        }
        if (!this.loadForm.Behavior) {
          flag = true;
          str = "请输入行为名称";
        }
        if (flag) {
          this.$message({
            type: "warning",
            message: str,
          });
          return;
        }

        this.subLoading = true;
        let params = {
          Id: this.id,
          ProgressGroupIds: this.loadForm.ProgressGroupIds,
          Behavior: this.loadForm.Behavior,
          Words: word,
          BehaviorInCheck: this.loadForm.BehaviorInCheck,
          configId: this.selRow.Id,
        };
        params = this.filterParams(params);
        this.$http
          .get(
            "/Management/SystemBehaviorAnalyse/SaveSystemBehaviorAnalyse.ashx",
            {
              params,
            }
          )
          .then((res) => {
            this.subLoading = false;
            if (res.res == 0) {
              this.$modal.hide("actionRuleXmodal");
              this.$emit("update");
              this.$message({
                type: "success",
                message: this.operationName == 1 ? "添加成功" : "修改成功",
              });
            }
          });
      }
    },
  },
  mounted() {},
  created() {},
};
